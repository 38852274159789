import React from 'react'

import Header from './../Header/Header'
import Routers from '../../router/Routers'
import Footer from './../Footer/Footer'
import UnderConstruction from '../../pages/UnderConstruction'

function Layout() {
  return (
    <>
      {/* <Header className="border border-dark"/>
      <Routers/>
      <Footer/> */}
      <UnderConstruction/>

    </>
  )
}

export default Layout