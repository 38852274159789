import React from 'react'
import {Container, Row, Col, Button} from 'reactstrap'
import '../styles/under-construction.css'

const ThankYou = () => {
  return <section>
    <Container>
        <Row>
            <Col lg="12" className='pt-5 text-center'>
                <div className="thank__you">
                    <span><i class="ri-building-2-line"></i></span>
                    <h1 className="mb-3">Under Construction</h1>
                    <h3 className="mb-4">Soon...</h3>
                </div>
            </Col>
        </Row>
    </Container>
  </section>
}

export default ThankYou